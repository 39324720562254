Wrapper = false;

Application = Class.create();
Application.prototype = {
	initialize: function() {
	},

	setup: async function(options) {
		this.options = Object.assign({
			printing:	false
		}, options);

		Application.current = this;
		Application.locked = false;
		Application.online = true;

		try {
			Application.online = window.sessionStorage.getItem('connection') == 'online';
		}
		catch(e) {
		}

		if (this.options.printing)
			this.platform = new PlatformPrinting
		else if (System.Runtime.Electron)
			this.platform = new PlatformElectron
		else if (System.Runtime.Capacitor)
			this.platform = new PlatformCapacitor
		else
			this.platform = new PlatformBrowser

		await this.platform.bootstrap();


		// Set title
		if (this.platform.title) {
			document.title = 'Salonhub - ' + this.platform.title.capitalize();
		}

		Application.initialize();


		if (this.options.printing) {
			document.body.classList.add('printing');
		}

		if (Application.clearActions) {
			Application.clearActions();
		}

		if (!(Settings.rights & _USER_ADMIN_ || Settings.rights & _USER_VIEW_ADMIN_ || this.platform.account.code == '')) {
			let challenge = await Application.getFromSession('challenge');

			if (challenge == this.platform.account.code) {
				Application.locked = false;
			} else {
				Application.locked = true;
			}
		}
	},

	_toggle: async function() {
		let challenge = await Application.getFromSession('challenge');
		
		if (challenge == this.platform.account.code) {
			this._lock();
		} else {
			this._challenge();
		}
	},

	_lock: function() {
		Application.storeInSession('challenge', null);
		this._check(true);
	},

	_unlock: function(data) {
		Application.storeInSession('challenge', data);
		this._check(true);
	},

	_check: async function(fire) {
		let lock = document.getElementById('lock');

		let challenge = await Application.getFromSession('challenge');
		
		if (challenge == this.platform.account.code) {
			Application.locked = false;

			if (lock) {
				lock.update("\uE91E");
				lock.classList.add('unlocked');
			}

			if (fire) {
				document.fire('application:unlock');
			}
		} else {
			Application.locked = true;

			if (lock) {
				lock.update("\uE91D");
				lock.classList.remove('unlocked');
			}

			if (fire) {
				document.fire('application:lock');
			}
		}
	},

	_challenge: function() {
		this.window = new ChallengeWindow(this.expertButton, {
			onCheck: (value) => {
				return value == this.platform.account.code;
			},

			onSave:	(data) => {
				Application.storeInSession('challenge', data);
				this._check(true);
			}
		});
	}
};

Application.initialize = function() {
	
	// Error Handler
	Telemetry.setup();
	
	// Set scrolling
	if (System.Features.Scroll)
		document.body.dataset.scroll = 'custom';
	else
		document.body.dataset.scroll = 'native';

	document.body.dataset.viewport = System.Type.Mobile && !System.Type.Tablet ? 'expand' : 'contain';
	
	// Set system classes
	if (System.OS.iOS) document.body.classList.add('iOS')
	if (System.OS.Android) document.body.classList.add('Android')
	if (System.OS.Chromebook) document.body.classList.add('Chromebook')
	if (System.OS.Mac) document.body.classList.add('Mac')
	if (System.OS.Windows) document.body.classList.add('Windows')
	if (System.OS.WebOS) document.body.classList.add('WebOS')
	if (System.Runtime.Browser) document.body.classList.add('Browser')
	if (System.Runtime.Electron) document.body.classList.add('Electron')
	if (System.Runtime.Capacitor) document.body.classList.add('Capacitor')
	if (System.Runtime.Application) document.body.classList.add('Application')
	if (System.Type.Mobile) document.body.classList.add('Mobile');
	if (System.Type.Tablet) document.body.classList.add('Tablet');
	if (System.Type.Desktop) document.body.classList.add('Desktop');

	// Focus on this window, so we can get keyboard events going
	window.focus();
};

Application.setupDatabase = function(client, salon) {
	return new Promise((resolve, reject) => {

		/* Disable database on webOS */

		if (navigator.userAgent.includes('NetCast')) {
			resolve();
			return;
		}

		let build = parseInt(Settings.version.split('.').pop(), 10) || 1;
		let name = 'offline/' + client + '/' + salon;

		let initialized = false;
		let database = window.indexedDB.open(name, build);

		database.onblocked = (event) => {
			initialized = true;

			Telemetry.log({
				type:		'database-upgrade-blocked',
				message:	'Error upgrading database, it is blocked',
				details: 	{
					name,
					build
				}
			});
		};

		database.onerror = (event) => {
			initialized = true;

			let message = '';

			if (database.error) {
				message = database.error.name + ': ' + database.error.message;
			}

			Telemetry.log({
				type:		'database-open-failed',
				message:	'Error opening database',
				details: 	{
					name,
					build,
					message
				}
			});

			resolve();
		};

		database.onupgradeneeded = (event) => {
			initialized = true;

			Database.initialize(event.target.result);

			if (event.oldVersion > 0) {
				Database.upgrade(event.oldVersion, event.newVersion);
			}
			else {
				Database.create();
			}
		};

		database.onsuccess = (event) => {
			initialized = true;
			
			Database.initialize(database.result);
			resolve();
		}

		setTimeout(() => {
			if (!initialized) {
				Telemetry.log({
					type:		'database-open-timout',
					message:	'Timout while opening database',
					details: 	{
						name,
						build
					}
				});

				resolve();
			}
		}, 1000)
	});
};

Application.supports = function(name, callback) {
	callback(false);
}

Application.uuid = function() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x7|0x8)).toString(16);
    });
    return uuid;
}

Application.getLocalPreference = function(id, defaultValue) {
	let client = Application.current.platform.account.client;
	let actor = Application.current.platform.credentials.actor;
	let key = `salonhub-${client}-${actor}-${id}`;

	try {
		let value = JSON.parse(localStorage.getItem(key));

		if (value) {
			return value;
		}
	}
	catch(e) {
	}

	return defaultValue;
}

Application.setLocalPreference = function(id, value) {
	let client = Application.current.platform.account.client;
	let actor = Application.current.platform.credentials.actor;
	let key = `salonhub-${client}-${actor}-${id}`;

	try {
		localStorage.setItem(key, JSON.stringify(value));
	}
	catch(e) {
		console.log(e);
	}
}


Application.storeInSession = function(id, value) {
	let client = Application.current.platform.account.client;
	let actor = Application.current.platform.credentials.actor;
	let key = `salonhub-${client}-${actor}-${id}`;

	try {
		sessionStorage.setItem(key, JSON.stringify(value));
		return true;
	}
	catch(e) {
	}
},

Application.getFromSession = async function(id) {
	let client = Application.current.platform.account.client;
	let actor = Application.current.platform.credentials.actor;
	let key = `salonhub-${client}-${actor}-${id}`;

	try {
		let value = JSON.parse(sessionStorage.getItem(key));

		if (value) {
			return value;
		}
	}
	catch(e) {
	}
},

Application.openWhatsapp = async function(value) {
	if (value.substring(0,1) == '0') {
		value = '+31' + value.substring(1);
	}

	value = value.replace(/[^0-9]/g, '');

	let result = await Application.openExternal({
		scheme:		'whatsapp://send?phone=',
		value
	});

	if (!result) {
		let explaination = 'Om deze actie uit te voeren moet de WhatsApp app geïnstalleerd zijn op uw apparaat.';

		if (System.Type.Desktop) {
			explaination = 'Om deze actie uit te voeren moet de WhatsApp Desktop applicatie geïnstalleerd zijn op uw computer.';
		}

		explaination += '<br><br>Of scan de QR code met uw telefoon om WhatsApp te openen.';

		new Window.Alert({
			message: 		'Kon WhatsApp niet openen',
			explaination: 	explaination,
			icon:			{ src: "Color#whatsapp" },

			render:  (container) => {
				new Widgets.QRCode(container, {
					value: 	`https://wa.me/${value}`,
					size: 	140,
					padding: {
						top: 	30,
					}
				});
			},
			
			button:			{ title: 'OK', color: 'green' },
		});
	}
}

Application.openVoicecall = async function(value) {
	if (value.substring(0,1) == '0') {
		value = '+31' + value.substring(1);
	}

	value = value.replace(/[^0-9+]/g, '');

	let result = await Application.openExternal({
		scheme:		'tel:',
		value
	});

	if (!result) {
		let explaination = 'Om deze actie uit te voeren moet een telefoon of VoIP app geïnstalleerd zijn op uw apparaat.';

		if (System.Type.Desktop) {
			explaination = 'Om deze actie uit te voeren moet een VoIP applicatie geïnstalleerd zijn op uw computer.';
		}

		new Window.Alert({
			message: 		'Kon niet bellen',
			explaination: 	explaination,
			icon:			{ src: "Color#phone" },
			button:			{ title: 'OK', color: 'green' },
		});
	}
}

Application.openMail = async function(value) {
	await Application.openExternal({
		scheme:		'mailto:',
		value
	});
}

Application.Actions = {
	_observer: 	null,
	_map:		new Map(),
	_visible:	new Set(),

	_callback:	function(e) {
		e.forEach(i => {
			if (i.intersectionRatio > 0) {
				Application.Actions._visible.add(i.target);
			} else {
				Application.Actions._visible.delete(i.target);
			}
		});

		Application.Actions._update();
	},

	_update: function() {
		Application.clearActions();

		Application.Actions._visible.forEach(i => {
			let actions = Application.Actions._map.get(i);

			actions.forEach(action => {
				Application.addActions(action);
			})
		})
	},

	_observe: function(element) {
		if (!Application.Actions._observer) {
			Application.Actions._observer = new IntersectionObserver(Application.Actions._callback);
		}
	
		Application.Actions._observer.observe(element);
	},

	_element: function(element) {
		if (element.container) {
			element = element.container;
		}

		if (element.internal && element.internal.container) {
			element = element.internal.container;
		}

		return element;
	},

	register: function(element, actions) {
		if (!Application.clearActions || !Application.addActions) {
			return;
		}

		element = Application.Actions._element(element);
		Application.Actions._map.set(element, actions);
		Application.Actions._observe(element);

		if (Application.Actions._visible.has(element)) {
			Application.Actions._update();
		}
	}
}
